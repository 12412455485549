import { defineStore } from "pinia";
import posthog from 'posthog-js'


export const usePosthogStore = defineStore("posthogStore", () => {

    const posthogRef = ref(null);

    const initPosthog = () => {
        posthog.init('phc_4V8PZoslMERVfWwoTALzEJhnGSNFmrsqpmXmMPqWdwB',
        {
            api_host: 'https://us.i.posthog.com',
            person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
        }
        )
        posthogRef.value = posthog;
    }

    const identifyUser = (distinctId, properties) => {
        posthog.identify(distinctId, properties)
    }

    return {
        posthogRef,
        initPosthog,
        identifyUser,
    };


}); 